import 'core-js/stable';
import 'regenerator-runtime/runtime';
import SmoothScroll from 'smooth-scroll';

import '@/js/legacy.js';

import '@/css/main.pcss';

const scroll = new SmoothScroll();

Array.from(
    document.querySelectorAll('.nav-secondary .nav-item [data-toggle=collapse]')
).forEach((element) => {
    element.addEventListener('click', (e) => {
        if (window.innerWidth < 768) {
            e.preventDefault();
        } else {
            Array.from(
                document.parentElement.querySelectorAll(
                    '.nav-secondary .nav-item [data-toggle=collapse] ul'
                )
            ).forEach((listElement) => {
                listElement.style.display = 'none'; // eslint-disable-line no-param-reassign
            });
        }
    });
});

const processElements = document.querySelectorAll("[id^='process']");
const descriptionElements = document.querySelectorAll('.tb-plz');
const initialElements = document
    .querySelectorAll('.text-block, .image-block, .links-block, .documents-block, .accordion-block, .divider-block');

const diagramDesktop = document.querySelector('.diagram.hidden-xs');
const diagramMobile = document
    .querySelector('.diagram.hidden-sm.hidden-md.hidden-lg');

if (diagramDesktop || diagramMobile) {
    if (window.innerWidth < 768) {
        diagramDesktop.parentNode.removeChild(diagramDesktop);
    } else {
        diagramMobile.parentNode.removeChild(diagramMobile);
    }
}

processElements.forEach((processElement) => {
    if (processElement.id.match(/[0-9]$/)) {
        const relatedDescriptionElements = document.querySelectorAll(
            `.tb-plz-${processElement.id.match(/\d+$/)[0]}`
        );

        processElement.addEventListener('click', () => {
            initialElements.forEach((element) => {
                element.classList.add('hidden');
            });

            Array.from(descriptionElements).forEach((element) => {
                element.classList.remove('in');
            });

            Array.from(relatedDescriptionElements).forEach((element) => {
                element.classList.add('in');
            });

            // noinspection JSUnresolvedFunction
            scroll.animateScroll(relatedDescriptionElements[0], false, {
                offset: 80,
            });
        });
    }
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}