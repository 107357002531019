/* eslint-disable */
/**
 * @author Samuel Beatty hello@pom-pom.ch;
 */
var App = App || {};

/**
 * Namespace which groups all global Options for the Application
 */
 App = (function() {

    return {
        setup: function() {

            /* In general: Links that point to # should be ignored */
            $("a[href='#']").click(function(e) { e.preventDefault(); });

            App.scrollTop();
            App.navigation();
            App.search();
            App.accordeon();
            App.svg();

            //Removes Ugly Colors
            $('body').keypress(function(e){
                //alert(e.which);
                if (e.which == 33) {
                    $('body').toggleClass('theme-nice');
                }
            });

        },

        scrollTop: function(data) {
            //Scroll To Rating Details
            $('.arrow-link').click(function(){
                $('html, body').animate({
                    scrollTop: $('#mainContent').offset().top - 30
                }, 500);
                return false;
            });
        },

        navigation: function(data) {
            $('#navSecondaryLeftToggle').click(function(event) {
                if ( $('.nav-full-width-left').hasClass('show') ) {
                    $('.nav-full-width-container').removeClass('show');
                    $(this).removeClass('active');
                } else {
                    $('.nav-full-width-container').removeClass('show');
                    $('.nav-full-width-left').addClass('show');
                    $('.nav-secondary-button').removeClass('active');
                    $(this).addClass('active');
                }
            });
            $('#navSecondaryRightToggle').click(function(event) {
                if ( $('.nav-full-width-right').hasClass('show') ) {
                    $('.nav-full-width-container').removeClass('show');
                    $(this).removeClass('active');
                } else {
                    $('.nav-full-width-container').removeClass('show');
                    $('.nav-full-width-right').addClass('show');
                    $('.nav-secondary-button').removeClass('active');
                    $(this).addClass('active');
                }
            });
            $('.navbar-toggle').click(function(event) {
                $('.nav-full-width-container').removeClass('show');
                $('.nav-secondary-button').removeClass('active');
            });
        },

        search: function() {
            //Toggle Search
            $('.js-toggle-search').click(function(event) {
                $('.search-container').toggleClass('display');
            });
            //Hide Search on ESC
            $('body').keypress(function(e){
                if (e.which == 27) {
                    if ($('.search-container').hasClass('display')) {
                        $('.search-container').toggleClass('display');
                    }
                }
            });
        },

        accordeon: function(data) {
            //Accordion Status Insicator Icons
            function toggleIcon(e) {
                $(e.target)
                .prev('.panel-heading')
                .find(".more-less")
                .toggleClass('indicator-plus indicator-minus');
            }
            $('.panel-group').on('hidden.bs.collapse', toggleIcon);
            $('.panel-group').on('shown.bs.collapse', toggleIcon);
        },

        svg: function() {
            //Platzierungsprozess Clicky Stuffy
            $('[data-process-id]').click(function() {
                $('.text-block, .image-block, .links-block, .documents-block, .accordion-block, .divider-block').hide();
                var processId = $(this).data('process-id');
                $('.tb-plz').removeClass('in');
                $('.tb-plz-' + processId).addClass('in');
                $('.svg-plz .active').removeClass('active');
                $(this).addClass('active');
                //Scroll to top of SVG Container
                $('html, body').animate({
                    scrollTop: $(".svg-container").prop("scrollHeight")
                }, 500);
            });
            $('.svg-hover').click(function(event) {
                //Removes All Active States
                $('.svg-hover').attr({
                    'class': 'svg-hover'
                });
                //Sets Active State
                $(this).attr({
                    'class': 'svg-hover svg-hover-active'
                });
            });
        }

    };
}());

 export default App;
