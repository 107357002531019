import $ from "../../buildchain/node_modules/expose-loader?exposes=$,jQuery!jquery"; // eslint-disable-line import/no-webpack-loader-syntax
import '@/legacy/js/vendor/jquery-plugins/jquery.bootstrap.js';
import App from '../../buildchain/node_modules/expose-loader?exposes=App!../legacy/js/src/App'; // eslint-disable-line import/no-webpack-loader-syntax
import 'bootstrap-validator';
import '@/legacy/js/vendor/citation';

import '@/legacy/less/style.less';

const affixElements = document.querySelectorAll('[class*=affix]');

App.setup();

function setAffix(affixElement) {
    affixElement.style.top = `${50}px`; // eslint-disable-line no-param-reassign
    affixElement.style.removeProperty('position');
    // affixElement.style.width = `${affixElement.parentNode.clientWidth}px`; // eslint-disable-line no-param-reassign
}

Array.from(affixElements).forEach((affixElement) => {
    const scrollTop = $(window).scrollTop();
    const elementOffset = $(affixElement).offset().top;
    const offset = elementOffset - scrollTop - 50;

    $(affixElement).affix({
        offset: {
            top: offset,
            bottom: 340,
        },
    });

    setTimeout(() => $(affixElement).affix('checkPosition'), 1);

    setAffix(affixElement);
    $(affixElement).on('affix.bs.affix', () => setAffix(affixElement));
});
