/* Add Citation and Link to Copied Text */

    today = new Date();
    dd = today.getDate();
    mm = today.getMonth()+1; //January is 0!
    yyyy = today.getFullYear();

    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    today = dd+'.'+mm+'.'+yyyy;
    Year = yyyy;
    Titel = document.title;

    function addCitation() {
    var body_element = document.getElementsByTagName('body')[0];
    var selectedText;
    selectedText = window.getSelection();
    autorVorname = document.getElementById("autorVorname").textContent;
    autorNachname = document.getElementById("autorNachname").textContent;


    if ((autorNachname == "") ||  (autorVorname =="")) {

        autor = "wif.swiss"
    }

    else {

        autor = autorNachname+", "+autorVorname;

    }


    var citation = "<br /><br />"+autor+", "+Year+": "+Titel+". In: Wissenslandschaft Fremdplatzierung. URL: <a href='"+document.location.href+"'>"+document.location.href+"</a>, abgerufen am: "+today+"";

    var copytext = selectedText + citation;
    var newdiv = document.createElement('div');
    body_element.appendChild(newdiv);
    newdiv.innerHTML = copytext;
    selectedText.selectAllChildren(newdiv);
    window.setTimeout(function() {
        body_element.removeChild(newdiv);
    },0);
}
document.oncopy = addCitation;